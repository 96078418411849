export function timeFix() {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome() {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}
/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader(callback) {
  let timer = 0
  let beforeScrollTop = window.pageYOffset
  callback = callback || function () { }
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function isIE() {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}
/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}
export function scorePassword(pass) {
  let score = 0
  if (!pass) {
    return score
  }
  // award every unique letter until 5 repetitions
  const letters = {}
  for (let i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1
    score += 5.0 / letters[pass[i]]
  }
  // bonus points for mixing it up
  const variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass)
  }
  let variationCount = 0
  for (var check in variations) {
    variationCount += (variations[check] === true) ? 1 : 0
  }
  score += (variationCount - 1) * 10
  return parseInt(score)
}

export function trim(obj) {
  if (obj === undefined) {
    return ''
  }
  for (var i in obj) {
    if (typeof (obj[i]) === 'object') {
      trim(obj[i])
    }
    if (typeof (obj[i]) === 'string') {
      obj[i] = obj[i].replace(/(^\s*)|(\s*$)/g, '')
    }
  }
  return obj
}

export function addDate(type, num) {
  let retDate = new Date()
  if (!num || !type) return new Date()
  if (type === 1) { // 天
    retDate = new Date().setDate(new Date().getDate() + num)
  } else if (type === 2) { // 月
    retDate = new Date().setMonth(new Date().getMonth() + num)
  } else if (type === 3) { // 小时
    retDate = new Date().setHours(new Date().getHours() + num)
  }
  return new Date(retDate)
}

export function getLocalTime(nS) {
  return new Date(parseInt(nS)).toLocaleString().replace(/:\d{1,2}$/, ' ')
}

// 根据经纬度计算距离, 返回单位为 公里
export function calcDistance(la1, lo1, la2, lo2) {
  if (!la1 || !la2) {
    return 0
  }
  const La1 = la1 * Math.PI / 180.0
  const La2 = la2 * Math.PI / 180.0
  const La3 = La1 - La2
  const Lb3 = lo1 * Math.PI / 180.0 - lo2 * Math.PI / 180.0
  let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(La3 / 2), 2) + Math.cos(La1) * Math.cos(La2) * Math.pow(Math.sin(Lb3 / 2), 2)))
  s = s * 6378.137
  // 返回公里数
  s = Math.round(s * 10000) / 10000
  return s
}

export function dateFormat(fmt, date) {
  let ret
  const opt = {
    "Y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "S+": date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    };
  };
  return fmt;
}

export function downloadFile(content, filename) {
  let url = content

  const link = document.createElement('a') // 创建a标签
  link.style.display = 'none' // 将a标签隐藏
  link.target = '_blank'
  link.href = url // 给a标签添加下载链接
  link.setAttribute('download', filename) // 此处注意，要给a标签添加一个download属性，属性值就是文件名称 否则下载出来的文件是没有属性的，空白白
  document.body.appendChild(link)
  link.click() // 执行a标签
  document.body.removeChild(link)
}

export function addDay(num) {
  let date = new Date()
  date = date.setDate(date.getDate() + num)
  date = new Date(date)
  return formateDateTime(date)
}

export function currentDateTime() {
  const date = new Date();
  return formateDateTime(date)
}
export function currentDate() {
  const date = new Date();
  return formateDateTime(date).split(" ")[0] + ' ' + '00:00:00'
}

export function formateDateTime(date) {
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = "00" + month
  month = month.substr(month.length - 2)
  let day = date.getDate();
  day = "00" + day
  day = day.substr(day.length - 2)
  let hour = "00" + date.getHours();
  hour = hour.substr(hour.length - 2);
  let minute = "00" + date.getMinutes();
  minute = minute.substr(minute.length - 2);
  let second = "00" + date.getSeconds();
  second = second.substr(second.length - 2);
  return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + minute
}

export function getHostName() {
  const envBaseUrl = process.env.VUE_APP_BASE_URL
  if (envBaseUrl) return envBaseUrl
  return window.location.protocol + "//" + window.location.host
}

export const download = function (url) {
  // 创建a标签
  const link = document.createElement('a')
  // href链接
  link.target = '_blank'
  link.setAttribute('href', url)
  // 自执行点击事件
  link.click()
}
