<template>
  <div class="page_wrapper">
    <div class="fix_head">
      <a href="#" @click="$router.back()">&lt; 返回</a>
      工单详情
    </div>
    <van-form>
      <p class="title">客户基本信息</p>
      <van-field v-for="item in customerInfo" :key="item.id" :value="item.value || '--'" :label="item.fieldLabel"
        disabled />
      <!-- <van-field v-model="formData.certificate" label="保险凭证" />
      <van-field v-model="formData.customerName" label="客户姓名" />
      <van-field v-model="formData.customerName" label="证件号码" />
      <van-field v-model="formData.customerName" label="联系电话" />
      <van-field v-model="formData.customerName" label="居住地址" /> -->
      <p class="title">车辆信息</p>
      <van-field v-for="item in vehicleInfo" :key="item.id" :value="item.value || '--'" :label="item.fieldLabel"
        disabled />
      <!-- <van-field v-model="formData.customerName" label="运营类型" />
      <van-field v-model="formData.customerName" label="是否新车" />
      <van-field v-model="formData.customerName" label="车牌号" />
      <van-field v-model="formData.customerName" label="车辆类型" />
      <van-field v-model="formData.customerName" label="车辆型号" />
      <van-field v-model="formData.customerName" label="发动机号" />
      <van-field v-model="formData.customerName" label="车架号" />
      <van-field v-model="formData.customerName" label="购车时间" />
      <van-field v-model="formData.customerName" label="车辆购置价" />
      <van-field v-model="formData.customerName" label="购置税" /> -->
      <p class="title">金融信息</p>
      <van-field v-for="item in financialInfo" :key="item.id" :value="item.value || '--'" :label="item.fieldLabel"
        disabled />
      <!-- <van-field v-model="formData.customerName" label="贷款机构" />
      <van-field v-model="formData.customerName" label="贷款金额" />
      <van-field v-model="formData.customerName" label="贷款期限" /> -->
      <p class="title">凭证信息</p>
      <van-field v-for="item in credentials" :key="item.id" :value="item.value || '--'" :label="item.fieldLabel"
        disabled />
      <!-- <van-field v-model="formData.customerName" label="第一受益人" />
      <van-field v-model="formData.customerName" label="服务费" />
      <van-field v-model="formData.customerName" label="生效时间" />
      <van-field v-model="formData.customerName" label="服务年限" />
      <van-field v-model="formData.customerName" label="经销商" />
      <van-field v-model="formData.customerName" label="经销商地址" /> -->
      <div class="div_btn">
        <van-button class="query" round block type="info" native-type="submit"
          @click="viewServiceCredentials">查看服务凭证</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { customerInsurance, viewVoucher } from '@/api/verification'
import { download } from '@/utils/util'
export default {
  data() {
    return {
      insuranceNo: 'CXWYP020230525003033',
      customerInfo: [],
      vehicleInfo: [],
      financialInfo: [],
      credentials: [],
      insuranceUploadType: [],
      insuranceId: ''
    }
  },
  created() {
    this.insuranceNo = this.$route.query.keyword || ''
    if (this.insuranceNo) {
      this.customerInsuranceFun()
    }
  },
  mounted() {

  },
  methods: {
    viewServiceCredentials() {
      // console.log(this.insuranceId, '===insuranceId')
      // 查看服务凭证
      viewVoucher({ id: this.insuranceId }).then(res => {
        if (res.success) {
          res.content && download(res.content.voucherUrl[0])
        }
      })
    },
    onSubmit() {
      console.log('submit');
      console.log(this.formData);
    },
    customerInsuranceFun() {
      customerInsurance({ insuranceNo: this.insuranceNo }).then(res => { // this.insuranceNo
        if (res.success) {
          const info = res.content
          info.customerInfo && (this.customerInfo = this.setValue(info.customerInfo))
          info.vehicleInfo && (this.vehicleInfo = this.setValue(info.vehicleInfo))

          info.financialInfo && (this.financialInfo = this.setValue(info.financialInfo))
          info.credentials && (this.credentials = this.setValue(info.credentials))
          info.insuranceUploadType && (this.insuranceUploadType = info.insuranceUploadType)
          this.insuranceId = info.insuranceId
          // console.log(this.sourceData, '======sourceData')
        }
      })
    },
    setValue(data) {
      return data.map(r => {
        let result = {}
        if (['text', 'number', 'date', 'special'].includes(r.inputType)) result = { ...r, value: r.fieldValue }
        if (['select', 'radio'].includes(r.inputType)) {
          result = { ...r }
          r.attach.forEach(item => {
            if (item.fieldValue == r.fieldValue) {
              result.value = item.fieldLabel
            }
          })
        }
        // console.log(r.fieldCode, '===r.fieldCode')
        if (r.fieldCode == 'vehicleModel') {
          result.value = r.fieldValue
        }
        return result
      })
    }
  }
}
</script>
<style lang="less" scoped>
.page_wrapper {
  padding: 15px;
  padding-top: 40px;
  font-size: 14px;

  .fix_head {
    background: #ED5D43;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    padding: 0 10px;
    text-align: center;
    z-index: 10;

    a {
      color: #fff;
      text-decoration: none;
      position: absolute;
      height: 100%;
      width: 60px;
      left: 0px;
    }
  }

  .title {
    font-weight: 600;
    color: #333333;
    margin-top: 10px;
  }

  /deep/.van-cell {
    padding: 10px 5px;

    .van-field__body {
      input {
        text-align: right;
        padding-right: 5px;
      }
    }
  }

  .div_btn {
    padding: 20px 10px;
    display: flex;
    justify-content: space-between;
  }

  .div_btn .query {
    background: #ED5D43;
    color: #fff;
    text-decoration: none;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    width: 60%;
    border: 0;
    margin: auto;
  }
}

/deep/.van-field--disabled {
  .van-field__label,
  .van-field__control:disabled {
    color: #323233 !important;
    -webkit-text-fill-color: #323233 !important;
  }
}
</style>


